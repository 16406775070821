import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertStatus } from "@monorepo/utils/src/api/convertDeleteStatus";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/fields";
import { elementStatuses } from "@monorepo/reports/src/views/FundsReportsView/FundsReportsView.vue";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      dateFirstArrivalTo?: string;
      dateFirstArrivalFrom?: string;
      dateLastArrivalTo?: string;
      dateLastArrivalFrom?: string;
      status?: string;
    };
  }
): void => {
  convertAutocompleteItemToApi(resultFilters, fields.FUND_PARENT_CODE, `fieldFilters.${fields.FUND_PARENT_CODE}.code`);
  formDateFilter(resultFilters, "dateFirstArrival");
  formDateFilter(resultFilters, "dateLastArrival");
  convertStatus(resultFilters, "isDeleted", "deleted", elementStatuses);
};
