







































































import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as FundsReportsView } from "@monorepo/reports/src/store/modules/fundsReportsView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/FundsReportsView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { convertApiItemToUi } from "@monorepo/reports/src/views/FundsReportsView/utils/convertApiItemToUi";
//import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/reports/src/views/FundsReportsView/utils/defaultCurrentFilters";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "fundsReportsView";
export const elementStatuses = [
  {
    title: "Активные",
    value: false,
    bool: false,
  },
  {
    title: "Удаленные",
    value: true,
    bool: true,
  },
];

export default defineComponent({
  name: "fundsReportsView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    InfiniteScroll,
    Table,
    Filters,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      isOpenFilters: false,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FUND_PARENT_CODE],
    };
  },
  computed: {
    ...mapGetters(SECTION_NAME, ["cells", "openedId", "infiniteId", "data", "totalLength", "isTableLoading"]),
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        elementStatuses,
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, FundsReportsView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
    });
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
