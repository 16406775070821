export interface IFundsReportsFields {
  FUND_IS_DELETED: string;
  FUND_DATE_FIRST_ARRIVAL: string;
  FUND_DATE_FIRST_ARRIVAL_FROM: string;
  FUND_DATE_FIRST_ARRIVAL_TO: string;
  FUND_PARENT_ID: string;
  FUND_PARENT_CODE: string;
  FUND_DATE_LAST_ARRIVAL: string;
  FUND_DATE_LAST_ARRIVAL_FROM: string;
  FUND_DATE_LAST_ARRIVAL_TO: string;
  FUND_STATUS: string;
  INDEX: string;
  START_DATE: string;
  END_DATE: string;
  FUND_NAME: string;
  FUND_SHORT_NAME: string;
  CASE_COUNT: string;
  INVENTORY_COUNT: string;
  EAD_COUNT: string;
  FUND_ID: string;
  FUND_NUMBER: string;
}

export const fields: IFundsReportsFields = {
  FUND_IS_DELETED: "status",
  FUND_DATE_FIRST_ARRIVAL: "dateFirstArrival",
  FUND_DATE_FIRST_ARRIVAL_FROM: "dateFirstArrivalFrom",
  FUND_DATE_FIRST_ARRIVAL_TO: "dateFirstArrivalTo",
  FUND_PARENT_ID: "parentId",
  FUND_PARENT_CODE: "parentCode",
  FUND_DATE_LAST_ARRIVAL: "dateLastArrival",
  FUND_DATE_LAST_ARRIVAL_FROM: "dateLastArrivalFrom",
  FUND_DATE_LAST_ARRIVAL_TO: "dateLastArrivalTo",
  FUND_STATUS: "deleted",
  INDEX: "index",
  START_DATE: "startDate",
  END_DATE: "endDate",
  FUND_NAME: "name",
  FUND_SHORT_NAME: "shortName",
  CASE_COUNT: "caseCount",
  INVENTORY_COUNT: "inventoryCount",
  EAD_COUNT: "eadCount",
  FUND_ID: "id",
  FUND_NUMBER: "number",
};
