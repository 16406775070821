import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/fields";

export const tableHeaders = [
  { text: "№ по порядку", defaultWidth: 120, value: fields.INDEX, isLink: false, isSorted: false, isHandle: true, id: uuid() },
  { text: "Начальная дата фонда", defaultWidth: 200, value: fields.START_DATE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Конечная дата фонда", defaultWidth: 200, value: fields.END_DATE, isSorted: false, isHandle: true, id: uuid() },
  { text: "Наименование фонда", isLink: false, defaultWidth: 200, value: fields.FUND_NAME, isSorted: false, isHandle: true, id: uuid() },
  { text: "Краткое наименование фонда", defaultWidth: 200, value: fields.FUND_SHORT_NAME, isSorted: false, isHandle: true, id: uuid() },
  { text: "Количество дел в фонде", defaultWidth: 200, value: fields.CASE_COUNT, isSorted: false, isHandle: true, id: uuid() },
  { text: "Количество описей в фонде", defaultWidth: 200, value: fields.INVENTORY_COUNT, isSorted: false, isHandle: true, id: uuid() },
  { text: "Количество ЭАД в фонде", defaultWidth: 200, value: fields.EAD_COUNT, isSorted: false, isHandle: true, id: uuid() },
  { text: "Идентификатор фонда", isLink: false, defaultWidth: 200, value: fields.FUND_ID, isSorted: false, isHandle: true, id: uuid() },
  { text: "Состояние", defaultWidth: 200, value: fields.FUND_STATUS, isSorted: false, isHandle: true, id: uuid() },
  { text: "№ фонда", isLink: false, defaultWidth: 200, value: fields.FUND_NUMBER, isSorted: false, isHandle: true, id: uuid() },
  { text: "Архив", isLink: false, defaultWidth: 200, value: fields.FUND_PARENT_ID, isSorted: false, isHandle: true, id: uuid() },
];
