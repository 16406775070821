import { fields } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.FUND_STATUS]: [],
  [fields.FUND_DATE_FIRST_ARRIVAL_FROM]: "",
  [fields.FUND_DATE_FIRST_ARRIVAL_TO]: "",
  [fields.FUND_PARENT_CODE]: {},
  [fields.FUND_DATE_LAST_ARRIVAL_FROM]: "",
  [fields.FUND_DATE_LAST_ARRIVAL_TO]: "",
});
